import {NAME_WS, TOKEN_WS, USER_WS} from "../../utils/constants/constants";
import {GROUP} from "../../utils/constants/back-office-constants";
import {WSInstance} from "./axios_config";

const axios = require('axios');

export async function getName() {
    try {
        return await WSInstance.get(NAME_WS, {withCredentials: true});
      } catch (error) {
            throw error;
      }
}
export async function getUser() {
    try {
        return await WSInstance.get(USER_WS, {withCredentials: true});
      } catch (error) {
            throw error;
      }
}

export async function getConnectedUser() {
      try {
            let result = await WSInstance.get(USER_WS, {withCredentials: true});
            const connectedUser = {
                  userId: result.data.cvcUser.userId,
                  email: result.data.cvcUser.email,
                  university: result.data.cvcUser.university,
                  roles: result.data.cvcUser.roles
            }
            return connectedUser;
      } catch (error) {
            throw error;
      }
}


export async function getToken() {
      try {
            return await axios.get(TOKEN_WS , {withCredentials: true});
      } catch (error) {
            throw error;
      }
}

export async function getListOfValuesByGroup(group) {
      let url;
      if (group === GROUP.university) {
            url = `${process.env.API_URL}/universities`;
      } else if (group === GROUP.universityAll){
          url = `${process.env.API_URL}/universities/all`;
      } else if (group === GROUP.universityAllWithAcademic){
            url = `${process.env.API_URL}/universities/allWithAcademic`;
      } else if (group === GROUP.topic){
          url = `${process.env.API_URL}/topics`;
      } else if (group === GROUP.courseTitle){
            url = `${process.env.API_URL}/course-titles`;
      }else if (group === GROUP.topicFull){
            url = `${process.env.API_URL}/topics/full`;
      } else if (group === GROUP.teacher){
            url = `${process.env.API_URL}/teachers`;
      } else if (group === GROUP.teacherVisible){
            url = `${process.env.API_URL}/courses/visible-teachers`;
      } else if (group === GROUP.roleGroup){
            url = `${process.env.API_URL}/roles-groups`;
      } else if (group === GROUP.userRoleGroupName){
            url = `${process.env.API_URL}/role-group-names`;
      } else if (group === GROUP.role){
            url = `${process.env.API_URL}/roles/STUDENT`;
      } else if (group === GROUP.keyword) {
            url = `${process.env.API_URL}/keywords`;
      } else if (group === GROUP.period) {
            url = `${process.env.API_URL}/periods`;
      }else if (group === GROUP.researchCenterUniversity) {
            url = `${process.env.API_URL}/researchCenterUniversity`;
      }else if (group === GROUP.researchCenterUniversityWithAcademic) {
            url = `${process.env.API_URL}/researchCenterUniversityWithAcademic`;
      } else if (group === GROUP.universityUnit) {
            url = `${process.env.API_URL}/university-units`;
      } else if (group === GROUP.academicDiscipline ||
                  group === GROUP.expertiseField || 
                  group === GROUP.academicTitle || 
                  group === GROUP.workingLanguage || 
                  group === GROUP.researchCountry || 
                  group === GROUP.researchProject) {
            url = `${process.env.API_URL}/list-of-dynamic-values/${group}`;
      } else if (group === GROUP.expertiseFieldWithAcademic ) {
            url = `${process.env.API_URL}/list-of-dynamic-values-WithAcademic/${GROUP.expertiseField}`;
      } else if (group === GROUP.disciplineFieldWithAcademic) {
            url = `${process.env.API_URL}/list-of-dynamic-values-WithAcademic/${GROUP.academicDiscipline}`;
      } else if (group === GROUP.countriesResearchFieldWithAcademic) {
            url = `${process.env.API_URL}/list-of-dynamic-values-WithAcademic/${GROUP.researchCountry}`;
      } else if (group === GROUP.workingLanguageFieldWithAcademic) {
            url = `${process.env.API_URL}/list-of-dynamic-values-WithAcademic/${GROUP.workingLanguage}`;
      } else {
            url = `${process.env.API_URL}/list-of-values/${group}`;
      }
      
      try {
            const data = await WSInstance.get( url, {withCredentials: true});
            return !!data ? data.data : [];
          } catch (error) {
            throw error;
      }
}

export async function searchUniversityById(id) {
      return await WSInstance.get(`${process.env.API_URL}/university/${id}`, { params: id });
}


export async function searchResearchCenteryById(id) {
      return await WSInstance.get(`${process.env.API_URL}/researchCenter/${id}`, { params: id });
}